<template>
  <div>
    <b-modal id="view-detail-modal" title="" centered size="lg">
      <b-table :stacked="true" :fields="viewFields" :items="items" borderless>
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | formatDate }}
        </template>
        <template #cell(createdBy)="data">
          <b-link
            class="link-underline"
            :to="{
              name:
                data.item.createdBy.typeId == 2
                  ? 'view-employee'
                  : 'view-administrator',
              params: { id: data.item.createdBy.id },
            }"
            v-if="data.item.createdBy"
          >
            {{ data.item.createdBy.name }}
          </b-link>
        </template>
        <template #cell(updatedAt)="data">
          {{ data.item.updatedAt | formatDate }}
        </template>
        <template #cell(updatedBy)="data">
          <b-link
            class="link-underline"
            :to="{
              name:
                data.item.updatedBy.typeId == 2
                  ? 'view-employee'
                  : 'view-administrator',
              params: { id: data.item.updatedBy.id },
            }"
            v-if="data.item.updatedBy"
          >
            {{ data.item.updatedBy.name }}
          </b-link>
        </template>
        <template #cell(deletedAt)="data">
          {{ data.item.deletedAt | formatDate }}
        </template>
        <template #cell(deletedBy)="data">
          <b-link
            class="link-underline"
            :to="{
              name:
                data.item.deletedBy.typeId == 2
                  ? 'view-employee'
                  : 'view-administrator',
              params: { id: data.item.deletedBy.id },
            }"
            v-if="data.item.deletedBy"
          >
            {{ data.item.deletedBy.name }}
          </b-link>
        </template>

        <template #cell()="scope">
          <div class="text-nowrap" v-if="scope.field.type == 'image'">
            <img
              class="cursor-pointer"
              v-if="scope.item[scope.field.key]"
              :width="scope.field.width ? scope.field : `50px`"
              :src="scope.item[scope.field.key]"
              :alt="scope.field.key"
              @click="previewImage(scope.item[scope.field.key])"
            />
          </div>
          <div class="text-nowrap" v-else>
            {{ scope.item[scope.field.key] }}
          </div>
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </b-table>
      <template #modal-footer>
        <div class="text-nowrap">
          <slot name="front-button" :item="item"></slot>
          <b-button
            :to="{
              name: `view-${type}`,
              params: { id: item.id },
            }"
            v-b-tooltip.hover
            :title="$t('general.view')"
            variant="primary"
            pill
            size="sm"
          >
            <feather-icon icon="EyeIcon" />
            {{ $t("general.view") }}
          </b-button>
          <b-button
            v-if="$can('update', type) && updatable"
            :to="{
              name: `update-${type}`,
              params: { id: item.id },
            }"
            v-b-tooltip.hover
            :title="$t('general.update')"
            variant="primary"
            pill
            size="sm"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("general.update") }}
          </b-button>
          <b-button
            v-if="$can('delete', type) && deletable && !archived"
            v-b-tooltip.hover
            :title="$t('general.delete')"
            variant="danger"
            pill
            size="sm"
            @click="destroy(item.id)"
          >
            <feather-icon icon="TrashIcon" />
            {{ $t("general.delete") }}
          </b-button>
          <b-button
            v-if="$can('restore', type) && deletable && archived"
            v-b-tooltip.hover
            :title="$t('general.restore')"
            variant="success"
            pill
            size="sm"
            @click="restore(item.id)"
          >
            <feather-icon icon="RepeatIcon" />
            {{ $t("general.restore") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BSkeletonTable,
  BSpinner,
  BLink,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BSkeletonTable,
    BSpinner,
    BLink,
    BButton,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  props: {
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    type: {
      type: String,
      default: "",
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    locale() {
      this.translateLabel();
    },
  },
  data() {
    return {
      items: [],
      item: {},
      viewFields: [],
    };
  },
  methods: {
    view(item) {
      this.items = [item];
      this.item = item;
      this.translateLabel();
      this.$bvModal.show("view-detail-modal");
    },
    hide() {
      this.$bvModal.hide("view-detail-modal");
    },
    translateLabel() {
      this.viewFields = JSON.parse(JSON.stringify(this.fields));
      this.viewFields.map((element) => {
        element.label = this.$t(element.label);
        return element;
      });
    },
    previewImage(image) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [image],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterDelete"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.yesDelete"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("destroy", id);
          }
        });
    },
    restore(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterRestore"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.yesRestore"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("restore", id);
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
