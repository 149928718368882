<template>
  <b-input-group
    class="input-group-merge"
    :class="errors.length > 0 ? 'is-invalid' : null"
  >
    <b-form-input
      :state="errors.length > 0 ? false : null"
      class="form-control-merge"
      :type="passwordFieldType"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="input"
      @blur="blur"
      autocomplete="new-password"
    />
    <b-input-group-append is-text>
      <feather-icon
        class="cursor-pointer"
        :icon="passwordToggleIcon"
        @click="togglePassword"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      passwordFieldType: "password",
      text: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    input(ev) {
      this.text = ev;
      this.$emit("input", this.text);
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>

<style scoped>
</style>
