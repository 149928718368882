var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"view-detail-modal","title":"","centered":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"text-nowrap"},[_vm._t("front-button",null,{"item":_vm.item}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
            name: ("view-" + _vm.type),
            params: { id: _vm.item.id },
          },"title":_vm.$t('general.view'),"variant":"primary","pill":"","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_vm._v(" "+_vm._s(_vm.$t("general.view"))+" ")],1),(_vm.$can('update', _vm.type) && _vm.updatable)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
            name: ("update-" + _vm.type),
            params: { id: _vm.item.id },
          },"title":_vm.$t('general.update'),"variant":"primary","pill":"","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t("general.update"))+" ")],1):_vm._e(),(_vm.$can('delete', _vm.type) && _vm.deletable && !_vm.archived)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('general.delete'),"variant":"danger","pill":"","size":"sm"},on:{"click":function($event){return _vm.destroy(_vm.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")],1):_vm._e(),(_vm.$can('restore', _vm.type) && _vm.deletable && _vm.archived)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('general.restore'),"variant":"success","pill":"","size":"sm"},on:{"click":function($event){return _vm.restore(_vm.item.id)}}},[_c('feather-icon',{attrs:{"icon":"RepeatIcon"}}),_vm._v(" "+_vm._s(_vm.$t("general.restore"))+" ")],1):_vm._e()],2)]},proxy:true}],null,true)},[_c('b-table',{attrs:{"stacked":true,"fields":_vm.viewFields,"items":_vm.items,"borderless":""},scopedSlots:_vm._u([{key:"cell(createdAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.createdAt))+" ")]}},{key:"cell(createdBy)",fn:function(data){return [(data.item.createdBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
            name:
              data.item.createdBy.typeId == 2
                ? 'view-employee'
                : 'view-administrator',
            params: { id: data.item.createdBy.id },
          }}},[_vm._v(" "+_vm._s(data.item.createdBy.name)+" ")]):_vm._e()]}},{key:"cell(updatedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.updatedAt))+" ")]}},{key:"cell(updatedBy)",fn:function(data){return [(data.item.updatedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
            name:
              data.item.updatedBy.typeId == 2
                ? 'view-employee'
                : 'view-administrator',
            params: { id: data.item.updatedBy.id },
          }}},[_vm._v(" "+_vm._s(data.item.updatedBy.name)+" ")]):_vm._e()]}},{key:"cell(deletedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.deletedAt))+" ")]}},{key:"cell(deletedBy)",fn:function(data){return [(data.item.deletedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
            name:
              data.item.deletedBy.typeId == 2
                ? 'view-employee'
                : 'view-administrator',
            params: { id: data.item.deletedBy.id },
          }}},[_vm._v(" "+_vm._s(data.item.deletedBy.name)+" ")]):_vm._e()]}},{key:"cell()",fn:function(scope){return [(scope.field.type == 'image')?_c('div',{staticClass:"text-nowrap"},[(scope.item[scope.field.key])?_c('img',{staticClass:"cursor-pointer",attrs:{"width":scope.field.width ? scope.field : "50px","src":scope.item[scope.field.key],"alt":scope.field.key},on:{"click":function($event){return _vm.previewImage(scope.item[scope.field.key])}}}):_vm._e()]):_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(scope.item[scope.field.key])+" ")])]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }