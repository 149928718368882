<template>
  <b-modal
    id="modal-stock-inout"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.save')"
    :cancel-title="$t('button.close')"
    centered
    :title="$t('general.stock')"
    @ok="handleOk"
  >
    <validation-observer ref="createForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="createForm"
        :form="$refs.createForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data"></n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.save") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";

const StockRepository = Repository.get("stock");

export default {
  components: {
    BModal,
    BSpinner,
    NFormConfirmation,
    NInput,
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      data: {
        stockBinId: "",
        itemId: "",
        type: "in",
        quantity: "",
        remark: null,
      },
      formInput: FormInput,
    };
  },
  methods: {
    show(stockBinId, itemId) {
      this.data = {
        stockBinId: stockBinId,
        itemId: itemId,
        type: "in",
        quantity: "",
        remark: null,
      };
      this.formInput.map((item) => {
        if (stockBinId && item.key == "stockBinId") {
          item.disabled = true;
        } else if (itemId && item.key == "itemId") {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        return item;
      });
      this.$bvModal.show("modal-stock-inout");
    },
    hide() {
      this.$bvModal.hide("modal-stock-inout");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.loading = true;
      StockRepository.create(this.data)
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
