export default [
  {
    key: 'stockBinId',
    label: 'field.bin',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'stockBin',
    selectionKey: 'id',
    selectionLabel: 'code',
    cols: 12,
  },
  {
    key: 'itemId',
    label: 'field.item',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'item',
    selectionKey: 'id',
    selectionLabel: 'label',
    cols: 12,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    rules: 'required|integer|min_value:1',
    type: 'currency',
    cols: 12,
  },
  {
    key: 'type',
    label: 'field.stock',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'general.in', value: 'in' },
      { text: 'general.out', value: 'out' },
    ],
    cols: 12,
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'max:300',
    type: 'textarea',
    cols: 12,
  },
]
