export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'stockLocation',
    sortable: true,
    sortField: 'stockLocationId',
    label: 'field.stockLocation',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'code',
    sortable: true,
    sortField: 'code',
    label: 'field.binCode',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'availableItemCode',
    label: 'field.itemCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'availableItemName',
    label: 'field.itemName',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantity',
    label: 'field.quantityInHand',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updateDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: "deletedAt",
    sortable: true,
    sortField: "deletedAt",
    label: "field.deletedDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "deletedBy",
    label: "field.deletedBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];