export default [
  {
    key: 'stockLocation',
    label: 'field.stockLocation',
  },
  {
    key: 'code',
    label: 'field.binCode',
  },
  {
    key: 'availableItemCode',
    label: 'field.itemCode',
  },
  {
    key: 'availableItemName',
    label: 'field.itemName',
  },
  {
    key: 'availableQuantity',
    label: 'field.quantityInHand',
  },
  {
    key: 'remark',
    label: 'field.remark',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
  {
    key: 'updatedAt',
    label: 'field.updateDate',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: "deletedAt",
    label: "field.deletedDate",
  },
  {
    key: "deletedBy",
    label: "field.deletedBy",
  },
];