export default [
  {
    key: 'stockLocationId',
    label: 'field.location',
    type: 'nAsynSingleSelection',
    repository: 'stockLocation',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'code',
    label: 'field.binCode',
    type: 'text',
    operator: '%',
  },
  {
    key: 'itemId',
    label: 'field.item',
    type: 'nAsynSingleSelection',
    repository: 'item',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
    md: 12,
    lg: 6,
  },
];