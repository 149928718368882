<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      @search="search"
      @reset="reset"
      @toggle-archived="toggleArchived"
      :fields="searchFields"
      show-toggle-archived
      :archived="params.archived"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', permissionType)">
            <b-button
              variant="primary"
              :to="{ name: `create-${permissionType}` }"
              >{{ $t("button.create") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-view-table
          @destroy="destroy"
          @restore="restore"
          :fields="viewTableFields"
          :type="permissionType"
          ref="viewTable"
          deletable
          :archived="params.archived"
        >
          <template #cell(stockLocation)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-stock-location',
                params: { id: data.item.stockLocation.id },
              }"
              v-if="data.item.stockLocation"
            >
              {{ data.item.stockLocation.name }}
            </b-link>
          </template>
          <template #cell(availableItemCode)="data">
            <span v-if="data.item.availableItem">
              {{ data.item.availableItem.itemCode }}
            </span>
          </template>
          <template #cell(availableItemName)="data">
            <span v-if="data.item.availableItem">
              {{ data.item.availableItem.itemName }}
            </span>
          </template>
          <template #cell(availableQuantity)="data">
            <span v-if="data.item.availableItem">
              {{ data.item.availableItem.quantity }}
            </span>
          </template>
          <template #cell(lockQuantity)="data">
            <span v-if="data.item.availableItem">
              {{
                data.item.availableItem.quantity -
                data.item.availableItem.availableQuantity
              }}
            </span>
            <span v-else> 0 </span>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template v-slot:front-button="data">
            <b-button
              v-b-tooltip.hover
              :title="$t('general.stock')"
              variant="primary"
              pill
              size="sm"
              v-if="$can('update', 'stock')"
              @click="stockInOut(data.item)"
            >
              <feather-icon icon="PackageIcon" />
              {{ $t("general.stock") }}
            </b-button>
          </template>
        </n-view-table>
        <n-table
          @sort-changed="sortChanged"
          @destroy="destroy"
          @restore="restore"
          @view="view"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :type="permissionType"
          :view-modal="true"
          deletable
          :archived="params.archived"
        >
          <template #cell(stockLocation)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-stock-location',
                params: { id: data.item.stockLocation.id },
              }"
              v-if="data.item.stockLocation"
            >
              {{ data.item.stockLocation.name }}
            </b-link>
          </template>
          <template #cell(availableItemCode)="data">
            <span v-if="data.item.availableItem">
              {{ data.item.availableItem.itemCode }}
            </span>
          </template>
          <template #cell(availableItemName)="data">
            <span v-if="data.item.availableItem">
              {{ data.item.availableItem.itemName }}
            </span>
          </template>
          <template #cell(quantity)="data">
            <span v-if="data.item.availableItem">
              {{ data.item.availableItem.quantity }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template v-slot:front-button="data">
            <b-button
              v-b-tooltip.hover
              :title="$t('general.stock')"
              variant="primary"
              pill
              size="sm"
              v-if="$can('update', 'stock')"
              @click="stockInOut(data.item)"
            >
              <feather-icon icon="PackageIcon" />
            </b-button>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
    <stock-modal ref="stockInOutModal" @success="getData"></stock-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import NViewTable from "@/components/NViewTable";
import TableFields from "./tableFields";
import ArchivedTableFields from "./archivedTableFields";
import ViewTableFields from "./viewTableFields";
import ViewArchivedTableFields from "./viewArchivedTableFields";
import SearchInputs from "./searchInput";
import StockModal from "@/views/stock/stockModal/Modal.vue";

const StockBinRepository = Repository.get("stockBin");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    NViewTable,
    ViewTableFields,
    vSelect,
    StockModal,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        stockLocationId: Number(this.$route.query.stockLocationId) || null,
        code: this.$route.query.code || null,
        archived: this.$route.query.archived == "true" ? true : false || false,
      },
    };
  },
  mounted() {
    if (this.params.archived) {
      this.fields = [...ArchivedTableFields];
      this.viewTableFields = [...ViewArchivedTableFields];
    }
    this.getData();
  },
  methods: {
    view(item) {
      this.$refs.viewTable.view(item);
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.params,
        searchFields: this.searchFields,
      };
      StockBinRepository.index(params)
        .then((response) => {
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    stockInOut(item) {
      this.$refs.stockInOutModal.show(item.id, item?.availableItem?.itemId);
    },
    destroy(id) {
      this.loading = true;
      StockBinRepository.destroy(id)
        .then((response) => {
          this.$refs.viewTable.hide();
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    restore(id) {
      this.loading = true;
      StockBinRepository.restore(id)
        .then((response) => {
          this.$refs.viewTable.hide();
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleArchived() {
      this.params.archived = !this.params.archived;
      if (this.params.archived) {
        this.fields = [...ArchivedTableFields];
        this.viewTableFields = [...ViewArchivedTableFields];
      } else {
        this.fields = [...TableFields];
        this.viewTableFields = [...ViewTableFields];
      }

      this.reset();
    },
  },
  setup() {
    const fields = TableFields;
    const viewTableFields = ViewTableFields;
    const searchFields = SearchInputs;
    const permissionType = "stock-bin";
    return {
      fields,
      viewTableFields,
      searchFields,
      permissionType,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
